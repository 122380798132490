import React from 'react'

const Download = (props) => {
    return(
        <div className="dl" key={props.id}>
        {props.bezeichnung}
        <a href={'files/'+ props.link}><button>&#8595;</button></a>
        </div>
    )
}

export default Download;