import React, { Component } from 'react';
import Logo from './logo.svg';
import ws from './ws.svg';
import './App.css';
import Woche from './Woche';
import Winter from './Winter';
import Download from './Download'
import data from './data.json'

class App extends Component {
  render() {
    return (
      <div>
        <img src={Logo} className="logo" alt=""/>

      <div className="content">

        <div className="downloads">
        <Woche woche="Woche 12" date="31.01.19" key="w12"
            topic={['JS Wiederholung, JS Image Slider', <a href="https://codepen.io/dpraschak/pen/vvdOQm" key="w7a1" target="_blank" rel="noopener"
            rel="noopener noreferrer">[&#8599;]</a>,', Intro jQuery', <a href="https://www.jquery.com" key="w7a1" target="_blank" rel="noopener"
            rel="noopener noreferrer">[&#8599;]</a>]}>
            <Download id="1" bezeichnung="Beispiel" link="dp1_jquery_beispiel.zip"/>
            <Download id="2" bezeichnung="Aufgabe A8" link="dp1_aufgabe8.zip"/>
            <Download id="3" bezeichnung="Aufgabe A9" link="dp1_aufgabe9.zip"/>
          </Woche>

        <Woche woche="Woche 11" date="24.01.19" key="w11"
            topic='Intro JavaScript, DOM-Manipulation, DOM-Selektoren, Events, CSS'>
            <Download id="1" bezeichnung="Beispiel" link="dp1_js_beispiele1.zip"/>
            <Download id="2" bezeichnung="Aufgabe A7" link="dp1_aufgabe7.zip"/>
            <Download id="3" bezeichnung="Aufgabe A7 Lösung" link="dp1_aufgabe7_lsg.zip"/>
          </Woche>

        <Woche woche="Woche 10" date="20.12.18" key="w10"
            topic='Wiederholung, QAs, Input, Beginn der eigenen Website'>
          </Woche>

        <Woche woche="Woche 9" date="20.12.18" key="w9"
            topic={['JS creative coding mit p5.js ', <a href="https://p5js.org" key="w8a1" target="_blank" rel="noopener"
            >[&#8599;]</a>,' Teil II, Sound Visualisierungen, Download folgt']}>
          </Woche>

          <Woche woche="Woche 8" date="13.12.18" key="w8"
            topic={['JS creative coding mit p5.js ', <a href="https://p5js.org" key="w8a1" target="_blank" rel="noopener"
            >[&#8599;]</a>,' Intro, for-Schleife, if-Bedingungen, Zeichnen, mouse-follow, mouse-click']}>

            <Download id="1" bezeichnung="Beispiele" link="dp1_p5js_beispiele1.zip"/>

          </Woche>


          <Woche woche="Woche 7" date="06.12.18" key="w7"
            topic={['CSS Transformations, CSS Transitions & CSS Animations, Cubic Bezier Generator', <a href="https://matthewlein.com/tools/ceaser" key="w7a1" target="_blank" rel="noopener"
            >[&#8599;]</a>]}>

            <Download id="1" bezeichnung="Beispiel CSS Transitions" link="dp1_css_transitions.zip"/>

          </Woche>


          <Woche woche="Woche 6" date="29.11.18" key="w6"
            topic={['Webtypografie Intro, REM, EM, Google Fonts ', <a href="https://fonts.google.com/" key="w6a1" target="_blank" rel="noopener"
            >[&#8599;]</a>, ', Adobe Fonts ', <a href="https://fonts.adobe.com/" key="w6a2" target="_blank" rel="noopener"
            >[&#8599;]</a>, ' // Download folgt...'] }>

          </Woche>


          <Woche woche="Woche 5" date="22.11.18" key="w5"
            topic={['Grid Systeme I, Skeleton Grid System', <a href="http://getskeleton.com" key="w5a1" target="_blank" rel="noopener"
            >[&#8599;]</a>]}>

            <Download id="1" bezeichnung="Beispiel 4" link="dp1_beispiel_4.zip"/>
            <Download id="2" bezeichnung="Aufgabe A6" link="dp1_aufgabe6.zip"/>
            <Download id="3" bezeichnung=" Aufgabe A6 Zw 1" link="dp1_aufgabe6_zwischenstand_1.zip" />
            <Download id="4" bezeichnung="Aufgabe A6 Lösung" link="dp1_aufgabe6_lsg.zip"/>

          </Woche>

          <Woche woche="Woche 4" date="15.11.18" key="w4"
            topic="Responsive Webdesign intro">

            <Download id="1" bezeichnung="Aufgabe A5" link="dp1_aufgabe5.zip"/>
            <Download id="2" bezeichnung="Aufgabe A5 Zwischenstand" link="dp_aufgabe5_zwischenstand.zip"/>
            <Download id="3" bezeichnung="Aufgabe A5 Lösung" link="dp_aufgabe5_lsg.zip" />
            <Download id="4" bezeichnung="Beispiel 3" link="dp1_beispiel_3.zip" />

          </Woche>


          <Woche woche="Woche 3" date="08.11.18" key="w3"
            topic={['Wiederholung + Übung der Positionierungen, Einführung in Figma',
            <a href="https://www.figma.com" target="_blank" rel="noopener" key="w1a1">[&#8599;]</a>]}>

            <Download id="1" bezeichnung="Aufgabe A3" link="dp1_aufgabe3.zip"/>
            <Download id="2" bezeichnung="Aufgabe A3 Lösung" link="dp_aufgabe3_lsg.zip" />
            <Download id="3" bezeichnung="Aufgabe A4*" link="dp1_aufgabe4.zip" />

          </Woche>

          <Woche woche="Woche 2" date="01.11.18" key="w2"
            topic="Wiederholung + Übung, Positionierung und Ausrichtung von Elementen
            per position: absolute + fixed.">

            <Download id="1" bezeichnung="Beispiel" link="dp1_beispiel_2_float_absolut.zip"/>
            <Download id="2" bezeichnung="Experimente A1" link="dp1_aufgabe1_lsg_exp_fixed.zip" />
            <Download id="3" bezeichnung="Aufgabe A2" link="dp1_aufgabe2.zip" />
            <Download id="4" bezeichnung="Lösung A2" link="dp1_aufgabe2_lsg.zip" />

            </Woche>

          <Woche woche="Woche 1" date="25.10.18" key="w1"
            topic="Basics von HTML + CSS. Tags: h1..., Images, div, floats,
            Box-Modell, font-sizes, font-family, width">

            <Download id="1" bezeichnung="Folien" link="dp1.pdf"/>
            <Download id="2" bezeichnung="Aufgabe A1" link="dp1_aufgabe1.zip" />
            <Download id="3" bezeichnung="Beispiele" link="dp1_beispiel_1.zip" />
            <Download id="4" bezeichnung="Lösung A1" link="dp1_aufgabe1_lsg.zip" />

            </Woche>

        </div>
      </div>
      <div className="ws">
        <Winter />
        <Winter />
        <Winter />
      </div>
      </div>
    );
  }
}

export default App;
