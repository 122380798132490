import React from 'react'

const Winter = () => {
    return(
      <svg width="100%" height="33%" preserveAspectRatio="none" viewBox="0 0 77 837" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="white">
        <path d="M74.9006 103.791L31.9226 91.176L74.9006 77.952V50.982L31.9226 37.758L74.9006 25.143V0.869995L14.0006 20.88V54.462L49.0616 64.467L14.0006 74.472L14.0006 108.054L74.9006 128.064L74.9006 103.791Z"></path>
        <path d="M35.4896 125.508C17.3936 125.508 13.1306 139.428 13.1306 164.397C13.1306 189.366 17.3936 203.286 34.1846 203.286C50.1056 203.286 51.7586 188.409 52.7156 164.397C53.2376 151.173 53.1506 148.998 55.4996 148.998C57.8486 148.998 58.3706 151.173 58.3706 161.787C58.3706 172.836 57.6746 178.056 54.4556 178.056L54.4556 200.676C70.0286 200.676 75.7706 187.278 75.7706 163.527C75.7706 139.515 70.0286 126.378 56.6306 126.378C42.4496 126.378 37.9256 136.905 37.0556 164.397C36.6206 178.665 36.2726 180.666 33.6626 180.666C31.4006 180.666 30.5306 178.665 30.5306 165.267C30.5306 150.912 32.5316 148.128 35.4896 148.128L35.4896 125.508Z"></path>
        <path d="M56.6306 308.943C71.7686 308.943 75.7706 296.676 75.7706 275.709C75.7706 255.003 71.7686 240.735 50.9756 240.735V263.355C56.6306 263.355 56.6306 268.575 56.6306 276.579C56.6306 284.148 56.1956 286.323 53.5856 286.323C45.0596 286.323 47.2346 239.865 24.4406 239.865H14.0006L14.0006 308.073H33.1406L33.1406 274.752C39.9266 279.102 36.8816 308.943 56.6306 308.943Z"></path>
        <path d="M13.1306 346.393C13.1306 370.492 23.3966 382.063 44.5376 382.063C65.5916 382.063 75.7706 370.492 75.7706 346.393C75.7706 322.207 65.6786 310.723 44.5376 310.723C23.3096 310.723 13.1306 322.207 13.1306 346.393ZM32.2706 346.393C32.2706 335.692 38.0126 333.343 44.5376 333.343C50.9756 333.343 56.6306 335.605 56.6306 346.393C56.6306 357.181 50.8886 359.443 44.5376 359.443C38.0996 359.443 32.2706 357.094 32.2706 346.393Z"></path>
        <path d="M33.1406 424.679H74.9006V402.059C66.2006 402.059 66.2006 393.62 66.2006 384.659H47.0606C47.0606 391.01 47.0606 395.708 52.8896 402.32L33.1406 402.146V384.659H14.0006L14.0006 442.079H33.1406V424.679Z"></path>
        <path d="M48.1916 502.909C50.8016 507.694 54.7166 509.956 60.0236 509.956C70.7246 509.956 75.7706 501.865 75.7706 480.376C75.7706 458.8 70.8116 450.796 60.0236 450.796C54.6296 450.796 50.8016 453.058 48.1916 457.843C45.4946 448.273 40.2746 444.706 32.3576 444.706C19.8296 444.706 13.1306 452.71 13.1306 480.376C13.1306 507.955 19.9166 516.046 32.3576 516.046C40.2746 516.046 45.4946 512.479 48.1916 502.909ZM60.9806 480.376C60.9806 488.902 59.5886 490.816 57.0656 490.816C54.5426 490.816 53.1506 488.989 53.1506 480.376C53.1506 471.763 54.5426 469.936 57.0656 469.936C59.5886 469.936 60.9806 471.85 60.9806 480.376ZM29.6606 480.376C29.6606 470.545 31.9226 467.326 35.4026 467.326C38.7956 467.326 40.9706 470.458 40.9706 480.376C40.9706 490.294 38.7086 493.426 35.4026 493.426C32.0096 493.426 29.6606 490.207 29.6606 480.376Z"></path>
        <path d="M76.6406 563.012V541.262L0.950627 518.642L0.950627 540.392L76.6406 563.012Z"></path>
        <path d="M56.6306 634.683C71.7686 634.683 75.7706 622.416 75.7706 601.449C75.7706 580.743 71.7686 566.475 50.9756 566.475V589.095C56.6306 589.095 56.6306 594.315 56.6306 602.319C56.6306 609.888 56.1956 612.063 53.5856 612.063C45.0596 612.063 47.2346 565.605 24.4406 565.605H14.0006L14.0006 633.813H33.1406L33.1406 600.492C39.9266 604.842 36.8816 634.683 56.6306 634.683Z"></path>
        <path d="M13.1306 672.133C13.1306 696.232 23.3966 707.803 44.5376 707.803C65.5916 707.803 75.7706 696.232 75.7706 672.133C75.7706 647.947 65.6786 636.463 44.5376 636.463C23.3096 636.463 13.1306 647.947 13.1306 672.133ZM32.2706 672.133C32.2706 661.432 38.0126 659.083 44.5376 659.083C50.9756 659.083 56.6306 661.345 56.6306 672.133C56.6306 682.921 50.8886 685.183 44.5376 685.183C38.0996 685.183 32.2706 682.834 32.2706 672.133Z"></path>
        <path d="M33.1406 750.419H74.9006V727.799C66.2006 727.799 66.2006 719.36 66.2006 710.399H47.0606C47.0606 716.75 47.0606 721.448 52.8896 728.06L33.1406 727.886V710.399H14.0006L14.0006 767.819H33.1406V750.419Z"></path>
        <path d="M75.7706 800.023C75.7706 778.534 68.9846 766.963 54.8036 766.963C41.5796 766.963 35.3156 778.534 35.3156 794.281C35.3156 801.502 36.3596 808.288 39.8396 813.421C32.6186 812.029 30.5306 807.94 30.5306 799.849C30.5306 788.539 32.5316 779.665 34.1846 771.052H16.7846C15.2186 778.447 13.1306 788.539 13.1306 803.677C13.1306 821.599 20.6996 836.563 45.9296 836.563C65.5916 836.563 75.7706 825.079 75.7706 800.023ZM45.3206 801.763C45.3206 791.845 47.5826 789.583 51.7586 789.583C56.0216 789.583 58.3706 791.932 58.3706 801.763C58.3706 811.594 56.0216 813.943 51.7586 813.943C47.4956 813.943 45.3206 811.681 45.3206 801.763Z"></path>
      </mask>
      <path d="M74.9006 103.791L31.9226 91.176L74.9006 77.952V50.982L31.9226 37.758L74.9006 25.143V0.869995L14.0006 20.88V54.462L49.0616 64.467L14.0006 74.472L14.0006 108.054L74.9006 128.064L74.9006 103.791Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M35.4896 125.508C17.3936 125.508 13.1306 139.428 13.1306 164.397C13.1306 189.366 17.3936 203.286 34.1846 203.286C50.1056 203.286 51.7586 188.409 52.7156 164.397C53.2376 151.173 53.1506 148.998 55.4996 148.998C57.8486 148.998 58.3706 151.173 58.3706 161.787C58.3706 172.836 57.6746 178.056 54.4556 178.056L54.4556 200.676C70.0286 200.676 75.7706 187.278 75.7706 163.527C75.7706 139.515 70.0286 126.378 56.6306 126.378C42.4496 126.378 37.9256 136.905 37.0556 164.397C36.6206 178.665 36.2726 180.666 33.6626 180.666C31.4006 180.666 30.5306 178.665 30.5306 165.267C30.5306 150.912 32.5316 148.128 35.4896 148.128L35.4896 125.508Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M56.6306 308.943C71.7686 308.943 75.7706 296.676 75.7706 275.709C75.7706 255.003 71.7686 240.735 50.9756 240.735V263.355C56.6306 263.355 56.6306 268.575 56.6306 276.579C56.6306 284.148 56.1956 286.323 53.5856 286.323C45.0596 286.323 47.2346 239.865 24.4406 239.865H14.0006L14.0006 308.073H33.1406L33.1406 274.752C39.9266 279.102 36.8816 308.943 56.6306 308.943Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M13.1306 346.393C13.1306 370.492 23.3966 382.063 44.5376 382.063C65.5916 382.063 75.7706 370.492 75.7706 346.393C75.7706 322.207 65.6786 310.723 44.5376 310.723C23.3096 310.723 13.1306 322.207 13.1306 346.393ZM32.2706 346.393C32.2706 335.692 38.0126 333.343 44.5376 333.343C50.9756 333.343 56.6306 335.605 56.6306 346.393C56.6306 357.181 50.8886 359.443 44.5376 359.443C38.0996 359.443 32.2706 357.094 32.2706 346.393Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M33.1406 424.679H74.9006V402.059C66.2006 402.059 66.2006 393.62 66.2006 384.659H47.0606C47.0606 391.01 47.0606 395.708 52.8896 402.32L33.1406 402.146V384.659H14.0006L14.0006 442.079H33.1406V424.679Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M48.1916 502.909C50.8016 507.694 54.7166 509.956 60.0236 509.956C70.7246 509.956 75.7706 501.865 75.7706 480.376C75.7706 458.8 70.8116 450.796 60.0236 450.796C54.6296 450.796 50.8016 453.058 48.1916 457.843C45.4946 448.273 40.2746 444.706 32.3576 444.706C19.8296 444.706 13.1306 452.71 13.1306 480.376C13.1306 507.955 19.9166 516.046 32.3576 516.046C40.2746 516.046 45.4946 512.479 48.1916 502.909ZM60.9806 480.376C60.9806 488.902 59.5886 490.816 57.0656 490.816C54.5426 490.816 53.1506 488.989 53.1506 480.376C53.1506 471.763 54.5426 469.936 57.0656 469.936C59.5886 469.936 60.9806 471.85 60.9806 480.376ZM29.6606 480.376C29.6606 470.545 31.9226 467.326 35.4026 467.326C38.7956 467.326 40.9706 470.458 40.9706 480.376C40.9706 490.294 38.7086 493.426 35.4026 493.426C32.0096 493.426 29.6606 490.207 29.6606 480.376Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M76.6406 563.012V541.262L0.950627 518.642L0.950627 540.392L76.6406 563.012Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M56.6306 634.683C71.7686 634.683 75.7706 622.416 75.7706 601.449C75.7706 580.743 71.7686 566.475 50.9756 566.475V589.095C56.6306 589.095 56.6306 594.315 56.6306 602.319C56.6306 609.888 56.1956 612.063 53.5856 612.063C45.0596 612.063 47.2346 565.605 24.4406 565.605H14.0006L14.0006 633.813H33.1406L33.1406 600.492C39.9266 604.842 36.8816 634.683 56.6306 634.683Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M13.1306 672.133C13.1306 696.232 23.3966 707.803 44.5376 707.803C65.5916 707.803 75.7706 696.232 75.7706 672.133C75.7706 647.947 65.6786 636.463 44.5376 636.463C23.3096 636.463 13.1306 647.947 13.1306 672.133ZM32.2706 672.133C32.2706 661.432 38.0126 659.083 44.5376 659.083C50.9756 659.083 56.6306 661.345 56.6306 672.133C56.6306 682.921 50.8886 685.183 44.5376 685.183C38.0996 685.183 32.2706 682.834 32.2706 672.133Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M33.1406 750.419H74.9006V727.799C66.2006 727.799 66.2006 719.36 66.2006 710.399H47.0606C47.0606 716.75 47.0606 721.448 52.8896 728.06L33.1406 727.886V710.399H14.0006L14.0006 767.819H33.1406V750.419Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
      <path d="M75.7706 800.023C75.7706 778.534 68.9846 766.963 54.8036 766.963C41.5796 766.963 35.3156 778.534 35.3156 794.281C35.3156 801.502 36.3596 808.288 39.8396 813.421C32.6186 812.029 30.5306 807.94 30.5306 799.849C30.5306 788.539 32.5316 779.665 34.1846 771.052H16.7846C15.2186 778.447 13.1306 788.539 13.1306 803.677C13.1306 821.599 20.6996 836.563 45.9296 836.563C65.5916 836.563 75.7706 825.079 75.7706 800.023ZM45.3206 801.763C45.3206 791.845 47.5826 789.583 51.7586 789.583C56.0216 789.583 58.3706 791.932 58.3706 801.763C58.3706 811.594 56.0216 813.943 51.7586 813.943C47.4956 813.943 45.3206 811.681 45.3206 801.763Z" stroke="#D7D7D7" stroke-width="2" mask="url(#path-1-inside-1)"></path>
    </svg>
    )
}

export default Winter;