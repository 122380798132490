import React from 'react';


const Woche = (props) => {
    return(
        
        <div className="download" >
            <div className="header">
                <div className="week">{props.woche}</div>
                <div className="date">{props.date}</div>
            </div>
            
            <div className="topics">
                {props.topic}
            </div>

            {props.children}

      </div>
    )
}

export default Woche
